<template>
  <footer class="bg-black" aria-labelledby="footerHeading">
    <div class="container py-12 lg:py-16">
      <!-- Footer above border -->
      <div
        v-if="hasMenuDisplay"
        class="mb-10 overflow-hidden lg:mb-16 pb-14 lg:pb-0 lg:grid lg:gap-8 lg:grid-cols-5"
      >
        <!-- Logo -->
        <div class="mt-5 mb-10">
          <nuxt-link :to="$i18nPath('/')">
            <img
              class="w-auto h-9"
              src="https://cdn.bbike-cdn.com.cn/benelli.bike/static/common/benelli.png"
              alt="benelli"
            />
          </nuxt-link>
        </div>

        <!-- Products -->
        <Collapsible
          v-if="store.enableShop && products && products.length >= 1"
          :aria-label="$t('common.products')"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          class="lg:pl-2 border-b border-solid border-[#666666] py-3 lg:border-none lg:py-0 lg:pt-3"
        >
          <template #trigger="{ isActive }">
            <div
              class="flex items-center justify-between cursor-default customTrigger"
            >
              <h3
                class="inline tracking-wider uppercase text-[22px] text-white"
              >
                {{ $t('common.products') }}
              </h3>
              <CloseIcon
                v-if="isActive"
                class="inline h-5 ml-1 text-white lg:hidden"
              />
              <PlusIcon v-else class="inline h-5 ml-1 text-white lg:hidden" />
            </div>
          </template>

          <ul class="pt-6 md:pt-2">
            <li v-for="(category, catIndex) in products" :key="catIndex">
              <nuxt-link
                :to="$i18nPath(`/search?category=${category.original_name}`)"
                class="text-lg font-light tracking-wide text-white hover:no-underline hover:opacity-9"
              >
                {{ category.name }}
              </nuxt-link>
            </li>
          </ul>
        </Collapsible>

        <!-- ways to buy -->
        <Collapsible
          :aria-label="$t('common.ways_to_buy')"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          class="py-3 border-b border-[#666666] border-solid lg:pl-2 lg:border-none lg:py-0 lg:pt-3"
        >
          <template #trigger="{ isActive }">
            <div
              class="flex items-center justify-between cursor-default customTrigger"
            >
              <h3
                class="inline text-left tracking-wider uppercase text-[22px] text-white font-medium"
              >
                {{ $t('common.ways_to_buy') }}
              </h3>
              <CloseIcon
                v-if="isActive"
                class="inline h-5 ml-1 text-white lg:hidden"
              />
              <PlusIcon v-else class="inline h-5 ml-1 text-white lg:hidden" />
            </div>
          </template>

          <ul class="pt-6 md:pt-2">
            <!-- eslint-disable-next-line -->
            <li>
              <nuxt-link
                :to="$i18nPath('/stores')"
                class="text-lg font-light tracking-wide text-white hover:no-underline hover:opacity-90"
              >
                {{ $t('common.search_stores') }}
              </nuxt-link>
            </li>

            <li>
              <nuxt-link
                :to="$i18nPath('/search')"
                class="text-lg font-light tracking-wide text-white hover:no-underline hover:opacity-90"
              >
                {{ $t('common.see_inventories') }}
              </nuxt-link>
            </li>

            <li>
              <nuxt-link
                :to="$i18nPath('/how-to-buy')"
                style="font-weight: 300"
                class="text-lg tracking-wide text-white hover:no-underline hover:opacity-90"
              >
                {{ $t('common.how_to_buy') }}
              </nuxt-link>
            </li>
          </ul>
        </Collapsible>

        <!-- Support -->
        <Collapsible
          :aria-label="$t('common.support')"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          class="py-3 border-b border-[#666666] border-solid lg:pl-2 lg:border-none lg:py-0 lg:pt-3"
        >
          <template #trigger="{ isActive }">
            <div
              class="flex items-center justify-between cursor-default customTrigger"
            >
              <h3
                class="inline text-[22px] tracking-wider uppercase text-white font-medium"
              >
                {{ $t('common.support') }}
              </h3>
              <CloseIcon
                v-if="isActive"
                class="inline h-5 ml-1 text-white lg:hidden"
              />
              <PlusIcon v-else class="inline h-5 ml-1 text-white lg:hidden" />
            </div>
          </template>

          <ul class="pt-6 md:pt-2">
            <li v-if="currentSiteSettings.intercom.integrate">
              <a
                @click.prevent="intercom.show()"
                v-text="$t('common.chat_online')"
                href="#"
                class="text-lg font-light tracking-wide text-white hover:no-underline hover:opacity-90"
              ></a>
            </li>
            <li>
              <nuxt-link
                :to="$i18nPath('/contact')"
                class="text-lg font-light tracking-wide text-white hover:no-underline hover:opacity-90"
              >
                {{ $t('common.contacts') }}
              </nuxt-link>
            </li>
            <li>
              <nuxt-link
                :to="
                  $i18nPath(
                    '/how-to-measure-your-head-and-find-your-helmet-size'
                  )
                "
                class="text-lg font-light tracking-wide text-white hover:no-underline hover:opacity-90"
              >
                {{ $t('common.helmet_size') }}
              </nuxt-link>
            </li>
            <li v-if="currentSite.news">
              <nuxt-link
                :to="$i18nPath('/news')"
                class="text-lg font-light tracking-wide text-white hover:no-underline hover:opacity-90"
              >
                {{ $t('common.news') }}
              </nuxt-link>
            </li>
            <li v-if="currentSiteSettings.become_a_partner">
              <nuxt-link
                :to="$i18nPath('/become-partner')"
                class="text-lg font-light tracking-wide text-white hover:no-underline hover:opacity-90"
              >
                {{ $t('common.become_a_partner') }}
              </nuxt-link>
            </li>
          </ul>
        </Collapsible>

        <!-- Legal -->
        <Collapsible
          :aria-label="$t('common.legal')"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          class="py-3 border-b border-[#666666] border-solid lg:pl-2 lg:border-none lg:py-0 lg:pt-3"
        >
          <template #trigger="{ isActive }">
            <div
              class="flex items-center justify-between cursor-default customTrigger"
            >
              <h3
                class="inline text-[22px] tracking-wider uppercase text-white font-medium"
              >
                {{ $t('common.legal') }}
              </h3>
              <CloseIcon
                v-if="isActive"
                class="inline h-5 ml-1 text-white lg:hidden"
              />
              <PlusIcon v-else class="inline h-5 ml-1 text-white lg:hidden" />
            </div>
          </template>

          <ul class="pt-6 md:pt-2">
            <li>
              <nuxt-link
                :to="$i18nPath('/warranty')"
                class="text-lg font-light tracking-wide text-white hover:no-underline hover:opacity-90"
              >
                {{ $t('common.warranty') }}
              </nuxt-link>
            </li>
          </ul>
        </Collapsible>
      </div>

      <!-- Social networks -->
      <section
        v-if="
          currentSiteSettings &&
          (currentSiteSettings.office_phone ||
            (checkSocialExist && currentSiteSettings.socials))
        "
        class="flex justify-center gap-4 px-6 md:justify-end"
      >
        <a
          v-if="currentSiteSettings.office_phone"
          :href="`tel:${currentSiteSettings.office_phone}`"
          target="_blank"
          class="flex gap-3 text-white opacity-20 hover:opacity-90"
        >
          <PhoneIcon class="w-5 h-5" />
          <span v-text="currentSiteSettings.office_phone" />
        </a>
        <a
          v-if="
            checkSocialExist &&
            currentSiteSettings.socials &&
            currentSiteSettings.socials.facebook
          "
          :href="currentSiteSettings.socials.facebook"
          target="_blank"
          aria-label="Facebook link"
          class="flex gap-3 text-white opacity-20 hover:opacity-90"
        >
          <FacebookIcon class="w-6 h-6" />
        </a>
        <a
          v-if="checkSocialExist && currentSiteSettings.socials.instagram"
          :href="currentSiteSettings.socials.instagram"
          target="_blank"
          aria-label="Instagram link"
          class="flex gap-3 text-white opacity-20 hover:opacity-90"
        >
          <InstagramIcon class="w-6 h-6" />
        </a>
        <a
          v-if="checkSocialExist && currentSiteSettings.socials.linkedin"
          :href="currentSiteSettings.socials.linkedin"
          target="_blank"
          aria-label="LinkedIn link"
          class="flex gap-3 text-white opacity-20 hover:opacity-90"
        >
          <LinkedInIcon class="w-6 h-6" />
        </a>
        <a
          v-if="checkSocialExist && currentSiteSettings.socials.twitter"
          :href="currentSiteSettings.socials.twitter"
          target="_blank"
          aria-label="Twitter link"
          class="flex gap-3 text-white opacity-20 hover:opacity-90"
        >
          <TwitterIcon class="w-6 h-6" />
        </a>
        <a
          v-if="checkSocialExist && currentSiteSettings.socials.youtube"
          :href="currentSiteSettings.socials.youtube"
          target="_blank"
          aria-label="YouTube link"
          class="flex gap-3 text-white opacity-20 hover:opacity-90"
        >
          <YouTubeIcon class="w-6 h-6" />
        </a>
        <a
          v-if="checkSocialExist && currentSiteSettings.socials.tiktok"
          :href="currentSiteSettings.socials.tiktok"
          target="_blank"
          aria-label="TikTok link"
          class="flex gap-3 text-white opacity-20 hover:opacity-90"
        >
          <TikTokIcon class="w-5 h-5" />
        </a>
      </section>

      <!-- Footer below border -->
      <div
        class="flex flex-col gap-8 px-6 pt-8 mt-4 border-t border-white lg:flex-row md:gap-4 md:flex md:items-center md:justify-between"
      >
        <KeewayGroupLogo />

        <div class="flex flex-col gap-4 md:gap-0">
          <!-- Copyright -->
          <p class="text-base font-normal text-white md:text-center opacity-20">
            Benelli Bike &copy; {{ $t('common.copyright') }} - {{ year }} BBIKE
            LIMITED -
            {{ $t('common.all_rights_reserved') }}
          </p>

          <!-- Legal -->
          <div
            class="flex flex-col flex-wrap gap-1 md:flex-row md:gap-2 xl:gap-4"
          >
            <nuxt-link
              :to="$i18nPath('/terms-and-conditions')"
              class="text-base font-normal text-white opacity-20 hover:no-underline hover:opacity-90"
            >
              {{ $t('common.terms_and_conditions') }}
            </nuxt-link>
            <nuxt-link
              :to="$i18nPath('/privacy-policy')"
              class="text-base font-normal text-white opacity-20 hover:no-underline hover:opacity-90"
            >
              {{ $t('common.privacy_policy') }}
            </nuxt-link>
            <nuxt-link
              :to="$i18nPath('/shipping-policy')"
              class="text-base font-normal text-white opacity-20 hover:no-underline hover:opacity-90"
            >
              {{ $t('common.shipping_policy') }}
            </nuxt-link>
            <nuxt-link
              :to="$i18nPath('/cookie-policy')"
              class="text-base font-normal text-white opacity-20 hover:no-underline hover:opacity-90"
            >
              {{ $t('common.cookie_policy') }}
            </nuxt-link>
          </div>
        </div>

        <a
          v-if="store.country === 'ar'"
          href="http://qr.afip.gob.ar/?qr=mLn1uJLEtha43TxloYSVnA,,"
          target="_F960AFIPInfo"
          class="footer__qrcode"
        >
          <img
            src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg"
            border="0"
            class="w-20 h-auto"
          />
        </a>

        <!-- Locales -->
        <div class="flex justify-center md:block">
          <LocaleSelector
            name="footer"
            class="flex justify-center"
            position="bottom"
          />
        </div>
      </div>
    </div>

    <WhatsappChat
      v-if="currentSiteSettings && currentSiteSettings.whatsapp"
      :phone="currentSiteSettings.whatsapp"
    />
    <MemeLink />
  </footer>
</template>
<script setup>
import { useMainStore } from '@/store/index'
import { useLocaleStore } from '@/store/locales'
import CloseIcon from '@/assets/svg/heroicons/x.svg?component'
import PlusIcon from '@/assets/svg/heroicons/plus.svg?component'
import PhoneIcon from '~/assets/svg/heroicons/phone.svg?component'
import FacebookIcon from '~/assets/svg/heroicons/facebook.svg?component'
import TwitterIcon from '~/assets/svg/heroicons/twitter.svg?component'
import InstagramIcon from '~/assets/svg/heroicons/instagram.svg?component'
import YouTubeIcon from '~/assets/svg/heroicons/youtube.svg?component'
import LinkedInIcon from '~/assets/svg/heroicons/linkedin.svg?component'
import TikTokIcon from '~/assets/svg/heroicons/tiktok.svg?component'

const store = useMainStore()
const nuxtApp = useNuxtApp()
const localeStore = useLocaleStore()

const $i18nPath = nuxtApp.$i18nPath
const $config = nuxtApp.$config.public
const browserWidth = ref(0)
const hasMenuDisplay = ref(false)
const state = reactive({ group_companies: [] })
const year = ref(new Date().getFullYear())
const intercom = ref(
  typeof nuxtApp.$intercom !== 'undefined' ? nuxtApp.$intercom : null
)

const products = store.products
const sites = store.sites
const country = store.country
const locales = localeStore.defaultLocales
const currentSiteSettings = store.currentSiteSettings
const currentSite = store.currentSite
const features = store.features

const textClass = computed(() => {
  return store.locale === 'el'
    ? 'text-[17px] 2xl:text-[20px] font-bold'
    : 'text-[30px]'
})

const countryName = computed(() => {
  const countryData = locales.length
    ? locales.find((x) => x.slug === country)
    : null
  return countryData ? countryData.country.name : ''
})

const checkSocialExist = computed(() => {
  let data = [
    'facebook',
    'instagram',
    'linkedin',
    'twitter',
    'youtube',
    'tiktok',
  ]
  if (!currentSiteSettings || !currentSiteSettings.socials) {
    return null
  }
  return data.find((_v) => currentSiteSettings.socials[_v])
})

const checkBrowserWidth = computed(() => {
  return browserWidth.value >= 1024 ? true : false
})

onMounted(() => {
  const findData = features.find((_v) => _v.slug == 'group-companies')

  if (findData && findData.settings) {
    state.group_companies = findData.settings
  }
  browserWidth.value = window.innerWidth
  hasMenuDisplay.value = true
  window.addEventListener('resize', windowResized)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', windowResized)
})

function windowResized() {
  browserWidth.value = window.innerWidth
}
</script>
